import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public scroll(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  public selectService(id: string){
    for(var i=0;i<this.services.length;i++){
      let selected = this.services[i].name == id;
      this.services[i].visible = selected;
    }
  }
  
  welcomeMessage: string = 'Here at ET Pressure cleaning we care about our customers, we are dedicated, take pride in our work, and will provide a clean that is out of this world!';
  quoteMessage: string = 'Commercial or residential we are here for you';

  licensedMessage: string = 'We are fully licensed and insured in the state of Florida. We are covered for up to two million in general liability. ';
  satisfactionMessage: string = 'We will do everything in our ability and knowledge to make sure your job exceeds your expectations.';
  freeQuoteMessage: string = 'Receive quotes online or over the phone for any job type. Contact us to get started.'; 

  serviceMessage: string = 'We are always responsive and want to hear from you. Contact us today through email or over the phone to receive your free quote.'
  services: any[] = [
    {
      'id':0,
      'name': 'Residential', 
      'label': 'Residential', 
      'description': 'We offer a variety of services including: roofs, patios, screen enclosures, brick, and driveways.',
      'visible':true,
      'serviceList': [
        {'listItem' : 'Roofs'}, 
        {'listItem' : 'Patios'},
        {'listItem' : 'Driveways'}, 
        {'listItem' : 'and more!'}] 
    },
    {
      'id':1,
      'name': 'Commercial', 
      'label': 'Commercial', 
      'description': 'Our cleaning services can help your commercial business stand out in this galaxy of dirty storefronts. \n\nWe offer cleaning to HOA\'s, office buildings, plazas, restaurants, parking lots and curbing, sidewalks, dumpster enclosures, awnings, and more.',
      'visible':false, 
      'serviceList': [
        {'listItem' : 'HOA\'s'}, 
        {'listItem' : 'Plazas'},
        {'listItem' : 'Parking lots'}, 
        {'listItem' : 'and more!'}] 
    }
  ];
}
